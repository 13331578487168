import { template as template_b9dc82adcd694ce290e7008d8a552a2a } from "@ember/template-compiler";
const FKLabel = template_b9dc82adcd694ce290e7008d8a552a2a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
